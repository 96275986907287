<template>
  <div class="container coupon-code">
    <van-search v-model="invited_coupon_code" show-action placeholder="请输入兑换码">
      <template #action>
        <div @click="toExchange">兑换</div>
      </template>
    </van-search>
    <van-tabs v-model="active" class="mb10" @click="onTab">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
    </van-tabs>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.coupon_id" class="list-item">
          <div :class="item.coupon_status?'list-item-hd':'list-item-hd list-item-hd-used'">
            <div class="list-item-hd-money">￥<span class="fz24">{{ item.coupon_dis_amount }}</span></div>
            <div class="list-item-hd-moneyHreshold">满{{ item.coupon_req_amount }}可用</div>
          </div>
          <div class="list-item-bd">
            <div class="list-item-bd-inner">
              <div class="list-item-bd-title">{{ item.coupon_title }}</div>
              <div class="list-item-bd-txt">{{ item.coupon_start_time }} 至 {{ item.coupon_end_time }}</div>
              <div v-if="active === 0 && item.invited_coupon_code" class="list-item-bd-code">
                <span>兑换码：{{item.invited_coupon_code}} </span>
                <span v-clipboard:copy="item.invited_coupon_code" v-clipboard:success="onCopy" v-clipboard:error="onError" class="list-item-bd-code-btn">复制</span>
              </div>
            </div>
            <div class="list-item-bd-actions">
              <van-button plain round size="small" :type="active?'default':'danger'" :disabled="!!active">{{ item.coupon_status_name }}</van-button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Search } from 'vant'
import { debounce } from '@/common/util'
import PageMixin from '@/mixins/page'

export default {
  name: 'Coupon',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
  },
  filters: {
    formatDate(value) {
      return value.slice(0, 10).replace(/-/g, '.')
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      active: 0,
      tabs: [
        { name: '未使用', status: 1, buttonTxt: '立即使用' },
        { name: '已使用', status: 2, buttonTxt: '已使用' },
        { name: '已过期', status: 0, buttonTxt: '已失效' }
      ],
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      invited_coupon_code:''
    }
  },
  created() {
    // this.onPage()
  },
  methods: {
    onTab: debounce(function(index) {
      this.listLoading = true
      this.listFinished = false
      this.pageCurrent = 1
      this.list = []
      this.onPage()
    }, 300),
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.coupon_index({ 'per-page': this.pageSize, page: this.pageCurrent, coupon_status: this.tabs[this.active].status })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '无可用优惠券~'
          this.pageCurrent += 1
        })
    },
    onCopy(){
        this.$toast('已复制兑换码至剪切板!')
    },
    onError(){
        this.$toast('操作失败!')
    },
    toExchange(){
      this.$toast.loading({
          mask: true,
          message: '请稍后...',
          duration: 0
      })
      this.$api.coupon_exchange({coupon_code:this.invited_coupon_code})
      .then(res=>{
        this.$toast('操作成功~')
        this.listLoading = true
        this.listFinished = false
        this.pageCurrent = 1
        this.list = []
        this.onPage()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.no-data .van-loading__spinner {
  display: none;
}

.list-item {
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 2px rgba(228, 228, 228, 0.5);
  &-hd {
    box-sizing: border-box;
    width: 100px;
    text-align: center;
    height: 100%;
    padding: 10px 0;
    background: linear-gradient(
      309deg,
      rgba(255, 112, 61, 1) 0%,
      rgba(255, 70, 57, 1) 100%
    );
    font-size: 14px;
    color: #fff;
    &-money {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  &-hd-used {
    background:#ccc !important;
  }
  &-bd {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    &-title {
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
      max-width: 190px;
      overflow: hidden;

    }
    &-txt {
      font-size: 12px;
      color: #999;
    }
    &-code {
      display:  flex;
      align-items: center;
      margin-top: 5px;
      &-btn{
        font-size: 12px;
        border: 1px solid #eee;
        border-radius: 14px;
        padding: 5px 10px;
        color: #999;
        margin-left: 5px;
      }
    }
    &-actions {
      font-size: 12px;
    }
  }
}
</style>
<style lang="less">
.coupon-code{
  .van-field__left-icon{
    display: none;
  }
}
</style>

